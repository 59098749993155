<div class="team-conatiner">
  <div class="team-section section-padding">
    <div class="container">
      <div class="section-title text-center">
        <h1>OUR Team</h1>
        <h2>We have Experienced Members</h2>
      </div>

      <div class="team-members">
        <div class="d">
          <div class="team-member">
            <div class="member-image">
              <img
                class="img-responsive"
                src="../../assets/icons/Screenshot from 2024-10-29 05-08-01.png"
                alt="image"
              />
            </div>
            <div class="team-info">
              <div class="member-info">
                <h4>Birasa eulade</h4>
                <p>Managing Director</p>
              </div>
              <p>
                "Unwavering commitment to excellence make your services a
                critical asset to your success."
              </p>
              <ul class="team-social list-inline">
                <li>
                  <a
                    alt="glandsb facebook link"
                    href="https://web.facebook.com/karamaga.yvan"
                    ><i class="fa fa-facebook"></i
                  ></a>
                </li>
                <li>
                  <a alt="glandsb twitter link"
                    ><i class="fa fa-twitter"></i
                  ></a>
                </li>
                <li>
                  <a alt="glandsb linkedin link"
                    ><i class="fa fa-linkedin"></i
                  ></a>
                </li>
                <li>
                  <a
                    alt="glandsb instgram link"
                    href="https://www.instagram.com/karamagayvan"
                    ><i class="fa fa-instagram"></i
                  ></a>
                </li>
              </ul>
            </div>
          </div>
          <div class="team-member">
            <div class="member-image">
              <img
                class="img-responsive"
                src="../../assets/image/manager.jpeg"
                alt="image"
              />
            </div>
            <div class="team-info">
              <div class="member-info">
                <h4>Kevin Rebera</h4>
                <p>Project Manager</p>
              </div>
              <p>
                "Your leadership and forward-thinking approach consistently turn challenges into opportunities, driving the team toward greater success."
              </p>
              <ul class="team-social list-inline">
                <li>
                  <a
                    alt="glandsb facebook link"
                    href="https://web.facebook.com/karamaga.yvan"
                    ><i class="fa fa-facebook"></i
                  ></a>
                </li>
                <li>
                  <a alt="glandsb twitter link"
                    ><i class="fa fa-twitter"></i
                  ></a>
                </li>
                <li>
                  <a alt="glandsb linkedin link"
                    ><i class="fa fa-linkedin"></i
                  ></a>
                </li>
                <li>
                  <a
                    alt="glandsb instagram link"
                    href="https://www.instagram.com/karamagayvan"
                    ><i class="fa fa-instagram"></i
                  ></a>
                </li>
              </ul>
            </div>
          </div>
          <div class="team-member">
            <div class="member-image">
              <img
                class="img-responsive"
                src="../../assets/icons/Screenshot from 2024-10-29 05-18-24.png"
                alt="image"
              />
            </div>
            <div class="team-info">
              <div class="member-info">
                <h4>Karamaga Yvan</h4>
                <p>Marketing Manager</p>
              </div>
              <p>
                "Your strategic vision and innovation make your campaigns a
                driving force behind your brand’s success."
              </p>
              <ul class="team-social list-inline">
                <li>
                  <a
                    alt="glandsb facebook link"
                    href="https://web.facebook.com/karamaga.yvan"
                    ><i class="fa fa-facebook"></i
                  ></a>
                </li>
                <li>
                  <a alt="glandsb twitter link"
                    ><i class="fa fa-twitter"></i
                  ></a>
                </li>
                <li>
                  <a alt="glandsb linkedin link"
                    ><i class="fa fa-linkedin"></i
                  ></a>
                </li>
                <li>
                  <a
                    alt="glandsb instagram link"
                    href="https://www.instagram.com/karamagayvan"
                    ><i class="fa fa-instagram"></i
                  ></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
