<div class="breadcrumb-section image-bg">
  <div class="overlay"></div>
  <div class="breadcrumb-content container">
    <h1>Our Services</h1>
    <ol class="breadcrumb">
      <li><a alt="glandsb home link">Home</a></li>
      <li class="active">Services</li>
    </ol>
  </div>
</div>
<!-- breadcrumb-section -->

<div class="recent-projects section-padding">
  <div class="container">
    <div class="row">
      <ul id="project-menu">
        <li
          (click)="changeComponent('Machine')"
          data-filter="*"
          class="cbp-filter-item"
          [ngClass]="activateComponent('Machine')"
        >
          Machine
        </li>
        <!-- <li
          (click)="changeComponent('RealEstate')"
          data-filter=".filter-two"
          class="cbp-filter-item"
          [ngClass]="activateComponent('RealEstate')"
        >
          Real Estate
        </li> -->
        <!-- <li
          (click)="changeComponent('Jobs')"
          data-filter=".filter-three"
          class="cbp-filter-item"
          [ngClass]="activateComponent('Jobs')"
        >
          Jobs
        </li> -->
        <li
          (click)="changeComponent('LandSurvey')"
          data-filter=".filter-one"
          class="cbp-filter-item"
          [ngClass]="activateComponent('LandSurvey')"
        >
          Land Survey
        </li>
        <li
          (click)="changeComponent('Construction')"
          data-filter=".filter-three"
          class="cbp-filter-item"
          [ngClass]="activateComponent('Construction')"
        >
          Construction
        </li>
      </ul>

      <!-- Menu -->

      <div class="machine" *ngIf="currentComponent === 'Machine'" id="projects">
        <div
          *ngFor="let data of machine"
          class="cbp-item project-content filter-one filter-five filter-two"
        >
          <div class="project-item">
            <a class="image-link"
              ><img
                class="img-responsive"
                [src]="appUrl + '/' + data.image"
                alt="glandsb rental equipment image"
            /></a>
          </div>
          <div class="project-info">
            <h3>{{ data?.name }}</h3>
            <div>
              <p>
                {{ data?.description }}
              </p>
              <div class="money-contact-btn">
                <div>RWF{{ data?.price }}/{{ data?.duration }}</div>
                <div (click)="machineContactUS(data._id)" class="contact-btn">
                  Contact US
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Machine -->

      <div
        *ngIf="currentComponent === 'RealEstate'"
        class="realEstate"
        id="projects"
      >
        <div
          *ngFor="let data of realEstate"
          class="cbp-item project-content filter-one filter-five filter-two"
        >
          <div class="project-item">
            <a class="image-link"
              ><img
                class="img-responsive"
                [src]="appUrl + '/' + data.image"
                alt="glandsb project image"
            /></a>
          </div>
          <div class="project-info">
            <div class="status-container">
              <div class="status">{{ data?.status }}</div>
            </div>

            <div class="location">
              <div>
                <img src="../../assets/icons/locator.svg" alt="" srcset="" />
              </div>
              <div class="name">{{ data?.location }}</div>
            </div>

            <div class="realEstate-details">
              <div class="detail">
                <div class="icon">
                  <img
                    src="../../assets/icons/double-bed.svg"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="value">{{ data?.bedroom }}</div>
              </div>
              <div class="detail">
                <div class="icon">
                  <img src="../../assets/icons/shower.svg" alt="" srcset="" />
                </div>
                <div class="value">{{ data?.bathroom }}</div>
              </div>
              <div class="detail">
                <div class="icon">
                  <img src="../../assets/icons/maximize.svg" alt="" srcset="" />
                </div>
                <div class="value">{{ data?.area }}Sq Ft</div>
              </div>
            </div>
            <div class="money-contact-btn">
              <div
                (click)="changeRoute('/service/RealEstate/' + data._id)"
                class="contact-btn"
              >
                Contact US
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Real Estate -->

      <div *ngIf="currentComponent === 'Jobs'" class="jobs" id="projects">
        <div
          *ngFor="let data of jobs"
          class="cbp-item project-content filter-one filter-five filter-two"
        >
          <div *ngIf="data.image !== ''" class="project-item">
            <a class="image-link"
              ><img
                class="img-responsive"
                [src]="appUrl + '/' + data?.image"
                alt="Glandsb real Estate image"
            /></a>
          </div>
          <div class="project-info">
            <h3>{{ data.title }}</h3>
            <p>
              {{ data.description }}
            </p>
            <div class="job-contact-btn">
              <div (click)="jobApply()" class="contact-btn">Contact US</div>
            </div>
          </div>
        </div>
      </div>

      <!-- Jobs -->
      <div
        *ngIf="currentComponent === 'LandSurvey'"
        class="LandSurvey"
        id="projects"
      >
        <app-land-survey />
      </div>
      <div
        *ngIf="currentComponent === 'Construction'"
        class="construction"
        id="projects"
      >
        <app-construction />
      </div>
    </div>
  </div>
</div>

<app-testimonial />
