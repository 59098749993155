import { Component, NgModule, OnInit } from '@angular/core';
import { TestimonialComponent } from '../../components/testimonial/testimonial.component';
import { SharedModule } from '../../shared/shared.module';
import { LandSurveyComponent } from '../../components/landSurvey/landSurvey.component';
import { ConstructionComponent } from '../../components/construction/construction.component';
import { ServiceApiService } from './servvice-api.service';
import { EnvironmentService } from '../../environments/environment.service';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-service',
  standalone: true,
  imports: [
    TestimonialComponent,
    SharedModule,
    LandSurveyComponent,
    ConstructionComponent,
  ],
  templateUrl: './service.component.html',
  styleUrl: './service.component.scss',
})
export class ServiceComponent implements OnInit {
  machine: Array<any> = [
    {
      image: 'image',
      name: 'machine',
    },
  ];
  realEstate: Array<any> = [
    {
      image: 'image',
      name: 'house',
      status: 'Rent',
    },
    {
      image: 'image',
      name: 'land',
      status: 'Sale',
    },
  ];

  jobs: Array<any> = [
    {
      image: 'image',
      name: 'machine',
      minDescription: '',
    },
  ];
  appUrl: string = '';

  currentComponent = 'Machine';

  constructor(
    private readonly ServiceApi: ServiceApiService,
    private readonly environment: EnvironmentService,
    private router: Router,
    private title: Title,
    private meta: Meta
  ) {}
  ngOnInit(): void {
    this.title.setTitle('Service');
    this.meta.addTags([
      {
        name: 'description',
        content:
          'construction equipment rental, real estate sales, land survey, construction bidding, construction jobs, Glandsb rwanda , heavy machinery rental, career in construction',
      },
      { name: 'robots', content: 'index, follow' },
      { name: 'canonical', content: 'https://www.glandsb.com/service/Machine' },
    ]);

    this.appUrl = this.environment.apiUrl;
    this.currentComponent = this.router.url.split('/')[2];
    this.changeComponent(this.currentComponent);
    window.scrollTo(0, 0);

    this.ServiceApi.getJobs().subscribe((res) => {
      this.jobs = res.data;
    });
    this.ServiceApi.getMachine().subscribe((res) => {
      this.machine = res.data;
      console.log('data', this.machine);
    });
    this.ServiceApi.getRealEstate().subscribe((res) => {
      this.realEstate = res.data;
    });
  }

  changeComponent(component: string) {
    this.currentComponent = component;
    console.log(this.currentComponent);
    this.router.navigate(['/service/' + component]);
  }
  activateComponent(component: string) {
    if (component === this.currentComponent) {
      return 'cbp-filter-item-active';
    } else {
      return '';
    }
  }

  goContactUs() {
    this.router.navigate(['/contactus']);
  }
  changeRoute(url: string) {
    this.router.navigate([url]);
  }
  jobApply() {
    this.router.navigate(['/job-apply/66f91c58ea7ffe08cb1bcea0']);
  }
  machineContactUS(id: string) {
    this.router.navigate([`/contactus/machine/${id}`]);
  }
}
