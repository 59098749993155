import { Component } from '@angular/core';

@Component({
  selector: 'app-service-details',
  standalone: true,
  imports: [],
  templateUrl: './service-details.component.html',
  styleUrl: './service-details.component.scss',
})
export class ServiceDetailsComponent {
  bigImage: string= '../../assets/image/project/8.jpg'
  changeBigImage(image: string){
    this.bigImage= image
  }
}
