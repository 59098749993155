<div class="service-section">
  <div [class]="['service-content', currentBackgroundImage]">
    <div class="services" (mouseleave)="onMouseLeave()">
      <div
        (mouseenter)="onMouseEnter('machine')"
        class="service machine crane-lifting image-bg"
      >
        <div class="bg"></div>
        <div class="box-title">
          <h3>equipment rental</h3>
        </div>
        <div (click)="changeRouter('/service/Machine')" class="more-btn">
          Learn More
        </div>
      </div>
      <div
        (mouseenter)="onMouseEnter('construction')"
        class="service house-plans construction image-bg"
      >
        <div class="bg"></div>
        <div class="box-title">
          <h3>construction</h3>
        </div>
        <div (click)="changeRouter('/service/Construction')" class="more-btn">
          Learn More
        </div>
      </div>
      <div
        (mouseenter)="onMouseEnter('land survey')"
        class="service home-plumbing land-survey image-bg"
      >
        <div class="bg"></div>
        <div class="box-title">
          <h3>land survey</h3>
        </div>
        <div (click)="changeRouter('/service/LandSurvey')" class="more-btn">
          Learn More
        </div>
      </div>
      <div
        (mouseenter)="onMouseEnter('Real estate')"
        class="service home-plumbing real-estate image-bg"
      >
        <div class="bg"></div>
        <div class="box-title">
          <img src="" alt="" srcset="" />
          <h3>Real estate</h3>
        </div>
        <div class="more-btn">Learn More</div>
      </div>
      <div
        (mouseenter)="onMouseEnter('Jobs')"
        class="service house-plans jobs image-bg"
      >
        <div class="bg"></div>
        <div class="box-title">
          <h3>Jobs</h3>
        </div>
        <div class="more-btn">Learn More</div>
      </div>
    </div>
  </div>
</div>
